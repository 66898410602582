import apiClient from '@/plugins/apiClientDrive'
import qs from 'qs';

export default {
    namespaced: true,
    state:{
        errors: null,
        message: null,
        success: null,
        queryParams: (localStorage.getItem('queryParams')) ? JSON.parse(localStorage.getItem('queryParams')) : null,
        single: (localStorage.getItem('myorder')) ? JSON.parse(localStorage.getItem('myorder')) : null,
        list: [],
        overview: null,
        filters: {
            range: null,
            sortBy: null,
            active: null,
            status: null,
        },
        ranges: [
            "THIS_MONTH",
            "THIS_WEEK",
            "LAST_MONTH",
        ],
        sortableBy: [
            "CLOSING_DATE_DESC",
            "PICKUP_DATE_DESC",
            "CLOSING_DATE_ASC",
            "PICKUP_DATE_ASC",
        ],
        availableStatus: [
            0, 1, 3, 9
        ],
        paymentMethod: {
            id: 3,
            name: "Wordline",
        },
        payment: (localStorage.getItem('paymentId')) ? localStorage.getItem('paymentId') : null,
    },
    getters:{
        errors: state => state.errors,
        success: state => state.success,
        message: state => state.message,
        single: state => state.single,
        list: state => state.list,
        overview: state => state.overview,
        filters: state => state.filters,
        ranges: state => state.ranges,
        sortableBy: state => state.sortableBy,
        availableStatus: state => state.availableStatus,
        paymentMethod: state => state.paymentMethod,
        payment: state => state.payment,
    },
    mutations:{
        saveQueryParams(state, data) {
            
                if(Object.keys(data).length){
                    if(data['utm_campaign'] ||  data['keyword'] ||  data['ad_group']){
                        console.log("queryParams", data)
                        state.queryParams = {
                            utm: data['utm_campaign'],
                            keyword: data['keyword'],
                            adGroup: data['ad_group'],
                        };
                        localStorage.setItem('queryParams', JSON.stringify( state.queryParams ))
                    }
                }
            
            console.log('mutation queryParams', state.queryParams);            
        },
        error(state, err) {
            console.log('mutation error',err);
            state.message = err.response.data.message;
            state.errors = err.response.data.errors;
            state.success = false;
        },
        setList(state, data) {
            console.log('mutation setList',data);
            state.list = data;
        },
        setOverview(state, data){
            console.log('mutation setOverview',data);
            state.overview = data;
        },
        setFilters(state, data){
            console.log('mutation setFilters',data);
            state.filters = data;
        },
        setSingle(state, data) {
            console.log('mutation setSingle',data);
            state.single = data;
            localStorage.setItem('myorder', JSON.stringify(state.single))
        },
        setPayment(state, data) {
            console.log('mutation setPayment',data);
            localStorage.setItem('paymentId',data.id)
            state.payment = data.id;
        },
        setSuccessPayCheckout(state, data) {
            console.log('mutation setSuccessPayCheckout',data);
            state.success = true;
        },
        setSucccessCheckIn(state, data){
            console.log('mutation setSucccessCheckIn',data);
            state.success = true;
        },
        reset(state){
            console.log('mutation reset');
            localStorage.removeItem('myorder')
            localStorage.removeItem('paymentId')
            state.errors = null
            state.message = null
            state.success = null
            state.filters = {
                range: null,
                sortBy: null,
                active: null,
                status: null,
            }
            state.single = null
            state.list = []
        },
    },
    actions:{
        saveQueryParams({commit}, data){
            commit('saveQueryParams', data);
        },
        resetErrors({commit}){
            commit('reset')
        },
        setFilters({commit}, data){
            commit('setFilters',data)
        },
        getList({commit, state}){
            return new Promise((resolve, reject) => {
                console.log('GET USER orders')
                console.log('GET LIST filters:', state.filters)
                let queryParams = [];
                
                for (const [key, value] of Object.entries(state.filters)) {
                    console.log(`${key}: ${value}`);
                    if(value!=null){
                        queryParams[key] = value;
                    }
                }

                let params =  qs.stringify(queryParams, { arrayFormat: 'indices' })
                apiClient.get("users/me/orders?"+params).then(resp => {
                        commit('setList', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('get car brands error ', err)
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        getOverview({commit,state}){
            return new Promise((resolve, reject) => {
                console.log('GET USER orders')
                let queryParams = [];
                
                for (const [key, value] of Object.entries(state.filters)) {
                    console.log(`${key}: ${value}`);
                    if(value!=null){
                        queryParams[key] = value;
                    }
                }

                let params =  qs.stringify(queryParams, { arrayFormat: 'indices' })

                apiClient.get("users/me/orders/overview?"+params).then(resp => {
                        commit('setOverview', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('get car brands error ', err)
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        createSingle({commit},data){
            return new Promise((resolve, reject) => {
                console.log('POST NEW order')

                if(localStorage.getItem('queryParams')){
                    data.tracking = JSON.parse(localStorage.getItem('queryParams'));
                }

                apiClient.post("users/me/orders",data).then(resp => {
                        commit('setSingle', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('get car brands error ', err)
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        getSingle({commit},order){
            return new Promise((resolve, reject) => {
                console.log('GET USER orders')
                apiClient.get("users/me/orders/"+order.id).then(resp => {
                        commit('setSingle', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('get car brands error ', err)
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        deleteSingle({commit},order){
            return new Promise((resolve, reject) => {
                console.log('GET USER orders')
                apiClient.delete("users/me/orders/"+order.id).then(resp => {
                        commit('setSingle', null);
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('get car brands error ', err)
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        checkInSingle({commit},order){
            return new Promise((resolve, reject) => {
                console.log('GET USER orders')
                apiClient.put("users/me/orders/"+order.id+'/webcheckin', order).then(resp => {
                        commit('setSucccessCheckIn', resp);
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('get car brands error ', err)
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        uploadDriverLicence({commit},payload){
            return new Promise((resolve, reject) => {
                console.log('UPLOAD DRIVER LICENCE orders')
                apiClient.post("users/me/orders/"+payload.orderId+'/drivers/'+payload.driverId+'/documents', payload).then(resp => {
                        commit('setSucccessCheckIn', resp);
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('get car brands error ', err)
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        getDriverLicence({commit},payload){
            return new Promise((resolve, reject) => {
                console.log('GET DRIVER LICENCE orders')
                apiClient.get("users/me/orders/"+payload.orderId+'/drivers/'+payload.driverId+'/documents').then(resp => {
                        commit('setSucccessCheckIn', resp);
                        resolve(resp.data);
                    })
                    .catch(err => {
                        console.log('get car brands error ', err)
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        payment({commit},data){
            let order = data.order;
            let payload = data.payload;
            return new Promise((resolve, reject) => {
                console.log('GET USER orders')
                apiClient.post("users/me/orders/"+order.id+'/payment', payload).then(resp => {
                        commit('setPayment', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('get car brands error ', err)
                        commit('error', err);
                        reject(err);
                    });
            })
        },
        checkout({commit},data){
            let order = data.order;
            let payload = data.payload;
            return new Promise((resolve, reject) => {
                console.log('GET USER orders')
                apiClient.post("users/me/orders/"+order.id+'/checkout', payload).then(resp => {
                        commit('setSuccessPayCheckout', resp.data);
                        resolve(resp);
                    })
                    .catch(err => {
                        console.log('Errror PayCheckout', err)
                        commit('error', err);
                        reject(err);
                    });
            })
        },
    },
}